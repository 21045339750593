


























import Vue from "vue";
import { Component } from "vue-property-decorator";
import { photoService } from "@/services/photo.service";

@Component
export default class DatasetUpload extends Vue {
  // Props

  // Data
  currentFile: File | null = null;
  progress = 0;
  uploadInProgress = false;
  rules = {
    file: [
      (v: File) => !!v || "File is required",
      (v: File) =>
        (!!v && v.name.endsWith(".zip")) || "Only .zip files are supported"
    ]
  };

  // Methods
  onFileChange(file: any) {
    this.progress = 0;
    this.currentFile = file;
  }

  createPhotoCollection() {
    if (this.currentFile === null) {
      return;
    }
    this.uploadInProgress = true;

    return photoService
      .addPhotoCollection(this.currentFile, event => {
        this.progress = Math.round((100 * event.loaded) / event.total);
        if (this.progress == 100) {
          this.$emit("fullProgress");
        }
      })
      .then(response => {
        this.uploadInProgress = false;
        this.$emit("createTask", Number(response.data.id));
      })
      .catch(err => {
        this.uploadInProgress = false;
        this.$emit("uploadError", err.response.data.info);
      });
  }
}
